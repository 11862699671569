@import "bootstrap-above.less";

html, body { margin:0px; padding:0px; position: relative; height: 100%; min-height: 100%;}


iframe{
	margin-bottom: 7px;
}

#mainmenu{
	min-height: 70px;
    border-bottom: 3px solid #000;
    border-radius: 0px;
	font-weight: 700;
	
	.navbar-nav {
		margin-top: 18px;
		text-transform: uppercase;
	}
	
	#btnSideMenu{
		color:#fff;
		background-color:#60a1bb;
		margin-top:15px;
		margin-left:5px;
	}
	
	li  {
		a {
			color: #000;
		}
		a:hover {
			color: @primary-color;
		}	
		&.active{
			a {
				color: @primary-color;
			}
		}
		&.slashbreak{
			color: red;
			font-weight: 400;
			padding: 10px 0px;
		}
	}
	&.open {
		background-color: #fff;
		padding-left:200px;
	}
	
}

h1{
	margin-top:0px;
}

a.navbar-brand{
	padding-top:3px;
	padding-right: 50px;
}

.navbar-toggle{
	border-radius: 0px;
  	margin: 0;
  	background-color: #60a1bb;
  	height: 67px;
  	width: 67px;
	color: #fff;
	font-size: 28px;
	border:0;
}

.dropdown-menu{
	margin-top: -10px;
}

.navbar-toggle:active{
	background-color:#60a1bb;
}

	a{
		color: @primary-color;
	}
	a:hover{
		color: #D88F91;
	}


#country {	
	.transition(all .5s ease);
	width: 74px;
	height: 67px;
	background-color: #f9f9f9;
	border-left: 1px solid #f3f3f3;
	padding-left: 19px;
	padding-top: 25px;
	position: absolute;
  	right: 68px;
	list-style:none;
	margin:0px;
	
	li {
		float:left;
		display:none;
		&.active {
			display:block;
		}
	}
	
	img {
		margin-top:-34px;
	}

	.norm{
		padding-left:15px;
	}
}

.SideSearch{
	width: 200px;
	margin-left: 17px;
	margin-top: 15px;
}

.right-menu{
	position: relative;
	
	#search{
		.transition(all .5s ease);
		height: 67px;
		padding:12px;
		padding-top:11px;
		background-color: #60a1bb;
		text-align: center;
		color: #fff;
		position: absolute;
		right: 0px;
		
		.searchinput {
			display:none;
			position: absolute;
			right: 0px;
			width: 250px;
			margin-top: 8px;
			margin-right: 75px;
			font-weight: 400;
			color:#000;
		}
		
		button {
			background:none;
			border:none;
			color:#fff;
			font-size:24px;
		}
	}
}

#video {
    position: absolute;
    height:100%;
    width:100%;
    overflow: hidden;
}

#bgvid { 
    min-width: 100%;
    min-height: 100%;
}

#wrapper{
    width: 100%;
    height: 100%;
}

#videooverlay {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.65) ;
    position: relative;
    text-align: center;
	
	.site-wrapper{
		display: table;
		width: 100%;
		min-height: 100%;
		.site-wrapper-inner{
			display: table-cell;
			vertical-align: middle;
		}
	}
	
	h1{
		font-weight: bold;
		font-family: Lato;
		font-size: 50px;
		color: #fff;
	}
	
	.scrolldown-middle{
		background-color:#60a1bb;
		padding: 1px 13px;
		border-radius:100px;
		cursor: pointer;
		color: #fff;
	}
	
}





.open{
	visibility: visible;
}


.contactbox{
    background-color: #f0f0f0;
    height: 100%;
    width: 100%;
    color: #000;
    padding: 0px 15px 15px 15px;
	float: left;
    
    .person{
        width: 100px;
        height: 150px;
        float: left;
    }
    .personinfo{
        float: left;
        width: 130px;
        height: 100px;
        padding-top: 15px;
    }
    .inputfields{
        float: left;
        margin-top:10px;
    }
    .btn{
        float: left;
        margin-top: 10px;
        width: 100%;
        text-align: center;
    }
	
	.success{
		background-color: #dff0d8;
	}
	
}

.btn-danger{
	border-color: transparent;
	background-color: @primary-color;
	&:hover{
		background-color: @primary-color;
		border-color: transparent;
	}
}

#color-section {
    width: 100%;
    background-color: @primary-color;
    text-align: center;
    color: #fff;
    margin-top: -15px;
    z-index: 5;
    position: relative;
	min-height: 200px;
	a {
    	color: #fff;
    }
	a:hover {
        text-decoration: underline;
    }
	.scrolldown-bottom {
		font-size:30px;
		background-color: @primary-color;
		padding:10px 15px;
		border-radius:100px;
		margin-top:-25px;
		cursor: pointer;
	}
	.h2-style{
		text-transform: uppercase;
		font-size: 29px;
	}
	.p-colorsection{
		width: 100%px;
		margin: auto;
		padding-bottom: 15px
	}
}

#gallery {
	
	img{
		margin-bottom:25px;
		border: 3px solid #f1f1f1;
	}
}

#images
{
	padding-bottom: 30px;
    .container
    {
        .row
        {
            margin-top: 60px;
			min-height:420px;
        }
		
    }
	h3{
		margin-top:10px;
		padding-bottom:20px;
		text-transform: uppercase;
	}
	
	a
	{
		color: #333;
	}
	
	a:hover
	{
		color: @primary-color;
		text-decoration: none;
	}
}

.footer
{
    width: 100%;
    background-color: #141e39;
    color: #fff;
    .container
    {
        .row
        {
            .col-md-12
            {
                margin-top:-90px;   
                img
                {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .row
        {
            .col-md-7
            {
                margin-top: -60px;
				padding-bottom:40px;

                h3
                {
                    font-weight: 700;
                }
				a{
					color: #fff;
					text-decoration: underline;
					
				}
				a:hover{
					color:#fff;
					text-decoration: underline;
				}
            }

            .col-md-5
            {
                margin-top: -60px;
				paddin-bottom: 10px;

                h3
                {
                    font-weight: 700;
                }

                img
                {
                    margin-top: 10px;
					padding-bottom:20px;
                }
            }
        }
    }
	
	.gotop{
	}
}

.rotate
{
	transform: rotate(7deg);
}


#topImg
{
    width: 100%;
    height:150px;
	margin-top: 50px;
	background-size:cover;
	background-position: top center;
}

.content
{
	padding-top:30px;
	padding-bottom: 100px;
    .container
    {
        min-height: 450px;
		p{
			margin: 0px 0px 18px;
		}
		.pdf{
			margin-top: 30px;
    		border: 1px solid #ccc;
		}
    }
	.employeeInfo{
		margin-top:40px;
	}
	.employeeStyle{
		margin-top:15px;
		background-color: #f9f9f9;
		padding: 10px;
	}
	.employeeTitle{
		font-size:20px;
		font-weight:700;
	}
	#searchResult{
		flote:left;
	}
	.searchResultBtn{
		flote:left;
		position: absolute;
	}
	.download_header{
		font-weight:700;
		font-family: 'Lato',sans-serif;
		font-size:15px;
	}
	.datafile{
		line-height: 2;
	}
}

	.Resulttext{
		font-size:20px;
		margin-bottom:0px;
	}

	.searchText{
		margin: 0px 0px 0px;
	}
	
	.searchTitle{
		color: #000;
		font-size:19px;
		a{
			color:#000;
		}
	}

	.resultBtn{
		margin-left: 10px;
  		color: #fff;
  		background-color: #60a1bb;
	}

	#searchresults{
		margin: 0px;
  		padding: 0px;
  		margin-top: 10px;
  		border-top: 1px solid #f0f0f0;
  		list-style: none;
		li{
			padding-top: 20px;
  			padding-bottom: 20px;
  			border-bottom: 1px solid #f0f0f0;
		}
	}

.result{
	padding-bottom: 150px;
}

.navbar
{
    margin-bottom: 0px;
}


#PageDown span
{
    margin-top: 22px;
    margin-left: -3px;
    font-size: 25px;
}

#Images
{
    width: 100%;
    min-height: 450px;
}

.copyrigt
{
    width: 100%;
    height: 35px;
    background: url(/Images/CopyrightLine.png);
    color: #444444;
	
	a
	{
		color:#444444;
	}
	
	a:hover
	{
		color:#fff;
		text-decoration: none;
	}
	
	.develop{
		text-align: right;
	}
}

.nav > li > a {
    position: relative;
    display: block;
	padding: 10px 8px;
}

.nav > li > a > img{
	padding-right:35px;
}

.White
{
    color: #fff;
}

#video {
    height:100%;
    width:100%;
    overflow: hidden;
    position: absolute;
}

#searchStyle
{
	background-color:#60a1bb;
	color:#fff;
	padding:25px;
}

.modal-header{
	display:none;
}

.ekko-lightbox-nav-overlay a
{
	color: #fff;
}

#country{
	li{
		padding-left: 24px;
    	padding-right: 26px;
		font-weight: 400;
		a{
			position: absolute;
  			padding-top: 15px;
  			margin-left: -20px;
		}
	}
}

@media (max-width: @screen-md-max) {
	#mainmenu {
		.navbar-nav{
			clear: both;
		}
	}
	#country {
		border-bottom: 1px solid #ccc;
		width: 74px;
		right: 66px;
	}
}

@media (max-width: @screen-sm-max) {
	.dropdown-menu{
		padding-bottom: 10px;
		.dropdown-list{
			padding-bottom: 10px;
		}
	}
	.content {
		.employeeInfo{
			padding-top: 41px;
			margin-top: 20px;
		}
	}
	.footer {
		.catalogs {
			margin-top: -45px;
		}
	}
	.contry{
		margin-right: -6px;
	}
	.right-menu{
		margin-right: -28px;
	}

	#images{
		padding-bottom:65px;
	}
}

@media (max-width: @screen-xs-max) {
	.logo{
		width: @logo-size;
	}
	#videooverlay {
		.scrolldown-middle {
			padding: 5px 13px;
		}
		h1 {
			font-size:20px;
		}
	}
	.fa-5x {
		  font-size: 3em;
	}
	.collapsMenu {
		text-align: right;
	}
	.right-menu {
		margin-right: 0px;
	}
	#color-section {
		padding-bottom: 18px;
	
		.h2-style {
			font-size: 23px;
		}
		.p-colorsection {
			font-size: 13px;
		}
	}

	.copyrigt {
		h5 {
			font-size: 11px;
		}
	}
	.contactbox {
		.personinfo {
			width: 110px;
		}
	}

 	#mainmenu {
		.navbar-nav {
			margin-left:0px;
			border-top: 1px solid #ddd;
			margin-left: 0px;
		}
	}
	.nav > li{
		border-bottom: 1px solid #ddd;
		font-weight: 400;
		text-transform: none;
		padding-left: 10px;

	}
	.footer {
		.block {
			display:block;
		}	
	}
}